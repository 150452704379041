import { React, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import MatchDetails from './MatchDetails'
import { Container, Row, Col } from 'react-bootstrap'
import RuneIconWithFallback from '../utilities/RuneIcon'
import SummonerSpellIconWithFallback from '../utilities/SummonerSpellIcon'
import ItemIconsWithFallBack from '../utilities/ItemIcons'
import AugmentIconWithFallback from '../utilities/AugmentIcon'
import { RiSwordFill } from "react-icons/ri";
import { BsFillShieldFill } from "react-icons/bs";
import { formatArenaPlacement } from '../helper_functions/utilities'
import ItemMatchHistoryIconWithFallback from '../utilities/ItemMatchHistoryIcon'
import { PiInfinityBold } from "react-icons/pi";
import { checkIfChampionIsKaynTransformation } from '../helper_functions/utilities'
import ChampionIconWithFallback from '../utilities/ChampionIcon'
import TrinketIconMatchHistoryIconWithFallback from '../utilities/TrinketMatchHistoryIcon'

const MatchHistory = ({orderedChampions, matchHistory, setSummonerData, summonerData }) => {

    const params = useParams()
    const displayRegion = params.displayRegion
    // const [showDetails, setShowDetails] = useState(false)

    // const displayName = params.displayNameZipped
    // const [gameName, tagLine] = displayName.split("-")

    function navAndSearchParticipant() {
        setSummonerData({})
    }


    // NOTE: The purpose of this function is to move all "real"/valid items to the first 5 slots in the array as players can freely put items 0-5 in any slot in game
    // function renderItemIcons(individualStats, width = "35", height = "35") {
    function renderItemIcons(individualStats, matchRecord) {
        const items = [0, 0, 0, 0, 0, 0, 0]; // There are always items 0-5 + 6 for ward slot
        let nonZeroCount = 0;
        for (let i = 0; i <= 6; i++) {
            const key = 'item' + i; // Key in individualStats i.e. item0, item1, item2 etc.
            const value = individualStats[key] // The actual item built i.e. 9002
            if (value !== undefined && value !== 0) { //If there is a real item ID in the JSON
                if (i === 6) {
                    items[6] = value; // Set the last element in the items array to the last element in the individualStats list of items
                } else {
                    items[nonZeroCount++] = value // Replace the existing placeholder 0 with the real itemId value
                }
            }
        }
        return (
            // <div className='grid grid-cols-4 gap-0.5'>
            //     {items.map((item, index) => (
            //         <img className='rounded-md'
            //             key={index}
            //             width="19"
            //             height="19"
            //             alt="item icon"
            //             // src={process.env.PUBLIC_URL + `/assets/item_icons/${item}.png`}
            //             src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${item}.png`}
            //         />
            //     ))}
            // </div>
            <div className='grid grid-cols-3 grid-rows-2 gap-0.5'>
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-1 row-start-1'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[0]}.png`}
                />
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-2 row-start-1'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[1]}.png`}
                />
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-3 row-start-1'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[2]}.png`}
                />
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-1 row-start-2'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[3]}.png`}
                />
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-2 row-start-2'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[4]}.png`}
                />
                <ItemMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className='rounded-md col-start-3 row-start-2'
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[5]}.png`}
                />
                <TrinketIconMatchHistoryIconWithFallback
                    individualStats={individualStats} matchRecord={matchRecord}
                    className="rounded-md col-start-4 row-start-1"
                    width="19"
                    height="19"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[6]}.png`}
                />
            </div>
        )
    }


    function renderChampionIcon(individualStats, matchRecord) {
        const championName = checkIfChampionIsKaynTransformation(individualStats?.championName, individualStats?.championTransform) 
        return (
            <div className="h-11 w-11" style={{ position: 'relative', display: 'inline-block' }}>
                {/* <img width={width} height={height} alt="champion icon" src={process.env.PUBLIC_URL + `/assets/champion/${individualStats?.championName}.png`} /> */}
                <ChampionIconWithFallback className={`rounded-full border-2 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border-gray-700" : individualStats?.win ? " border-cyan-900" : "border-rose-950"}`} width={"40"} height={"40"} alt="champion icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championName}.png`} />
                <div className={`rounded-full ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"} h-5 w-5 flex justify-center items-center font-bold`} style={{ position: 'absolute', bottom: '0', right: '0', fontSize: '12px' }}>
                    <div className='text-xxs'>{individualStats?.champLevel}</div>
                </div>
            </div>
        )
    } 
    
    function renderSummonerSpells(individualStats) {
        return (
            <div className='flex gap-0.5 mb-0.5'>
                <SummonerSpellIconWithFallback width="19" height="19" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${individualStats?.summoner1Id}.png`} />
                <SummonerSpellIconWithFallback width="19" height="19" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${individualStats?.summoner2Id}.png`} />
            </div>
        )
    }

    function renderRuneIcons(individualStats) {
        return (
            <div className='flex gap-0.5'>
                <RuneIconWithFallback individualStats={individualStats} width="19" height="19" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/${individualStats?.perks?.styles?.[0]?.selections?.[0]?.perk}.png`} />
                <RuneIconWithFallback individualStats={individualStats} width="19" height="19" format="p-[2px]" alt="secondary tree" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}.png`} />
            </div>
        )
    }

    function renderAugmentIcons(individualStats) {

        return (
            <div>
                <div className='flex gap-0.5 mb-[2px]'>
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment1}.png`} />
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment2}.png`} />
                </div>
                <div className='flex gap-0.5'>
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment3}.png`} />
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment4}.png`} />
                </div>
            </div>
        )
    }

    // function renderHighestStreak(individualStats) {
    //     if (individualStats?.pentaKills) {
    //         return "PENTA KILL"
    //     } else if (individualStats?.quadraKills) {
    //         return "QUADRA KILL"
    //     } else if (individualStats?.tripleKills) {
    //         return "TRIPLE KILL"
    //     } else if (individualStats?.doubleKills) {
    //         return "DOUBLE KILL"
    //     } else {
    //         return null
    //     }
    // }

    function renderHighestStreak(individualStats) {
        let streakText = "";
        let streakStyle = "";

        if (individualStats?.pentaKills) {
            streakText = "PENTA KILL";
            streakStyle = "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white shadow-lg";
        } else if (individualStats?.quadraKills) {
            streakText = "QUADRA KILL";
            streakStyle = "bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white shadow-md";
        } else if (individualStats?.tripleKills) {
            streakText = "TRIPLE KILL";
            streakStyle = "bg-gradient-to-r from-yellow-300 via-yellow-400 to-green-300 text-yellow-900 shadow";
            // } else if (individualStats?.doubleKills) {
            //     streakText = "DOUBLE KILL";
            //     streakStyle = "bg-gradient-to-r from-yellow-200 to-yellow-300 text-yellow-900  shadow-sm";
        } else {
            return null;
        }

        return (
            <div className={`mt-2 text-xxs font-extrabold rounded-full px-1 py-1 text-center ${streakStyle}`}>
                {streakText}
            </div>
        );
    }

    function calculateELOBadgeStyling(averageRank) {
        let rankStyle = "";
        let rankShort = "";

        if (averageRank?.includes("Challenger")) {
            rankStyle = "bg-yellow-400 text-sky-700"; // Challenger icon is typically gold with dark text
            rankShort = "C";
        } else if (averageRank?.includes("Grand")) {
            rankStyle = "bg-red-800 text-zinc-300"; // Grandmaster icon is red with yellowish text
            rankShort = "GM";
        } else if (averageRank?.includes("Master")) {
            rankStyle = "bg-purple-600 text-white"; // Master icon is purple with white text
            rankShort = "M";
        } else if (averageRank?.includes("Diamond")) {
            rankStyle = "bg-blue-600 text-white"; // Diamond icon is blue with white text
            rankShort = "D";
        } else if (averageRank?.includes("Emerald")) {
            rankStyle = "bg-green-600 text-white"; // Emerald icon is green with white text
            rankShort = "E";
        } else if (averageRank?.includes("Platinum")) {
            rankStyle = "bg-sky-500 text-white"; // Platinum icon is sky blue with white text
            rankShort = "P";
        } else if (averageRank?.includes("Gold")) {
            rankStyle = "bg-yellow-600 text-yellow-200"; // Gold icon is gold with dark brown text
            rankShort = "G";
        } else if (averageRank?.includes("Silver")) {
            rankStyle = "bg-slate-400 text-gray-800"; // Silver icon is gray with darker gray text
            rankShort = "S";
        } else if (averageRank?.includes("Bronze")) {
            rankStyle = "bg-orange-800 text-yellow-600"; // Bronze icon is orange with dark yellow text
            rankShort = "B";
        } else if (averageRank?.includes("Iron")) {
            rankStyle = "bg-gray-600 text-gray-200"; // Iron icon is dark gray with light gray text
            rankShort = "I";
        } else {
            rankStyle = "bg-neutral-400 text-white"; // Neutral fallback
            rankShort = "UR";
        }

        // Extract the number from the averageRank string (e.g., "4" from "SILVER 4")
        const rankNumber = averageRank?.split(' ')[1] || '';

        return (
            <div className={`text-xs font-semibold ${rankStyle} rounded-full px-2`}>
                {rankShort}{rankNumber}
            </div>
        );
    }


    function calculateCs(individualStats, matchRecord) {
        let match = matchRecord?.metadata
        let totalCs = individualStats?.neutralMinionsKilled + individualStats?.totalMinionsKilled
        let gameLengthMinutes = match?.info?.gameDuration / 60
        let csPerMin = totalCs / gameLengthMinutes

        let formattedGold = (individualStats?.goldEarned / 1000).toFixed(1);
        if (formattedGold.endsWith('.0')) {
            formattedGold = formattedGold.slice(0, -2);
        }

        return (
            <div className='whitespace-nowrap overflow-hidden text-ellipsis'>
                {matchRecord?.queueId !== 1700 && <div className='fw-bold whitespace-nowrap overflow-hidden text-ellipsis'>{totalCs} ({csPerMin.toFixed(1)} CSM)</div>}
                {/* <span className='fw-bold'>Gold:</span><span> {Intl.NumberFormat().format(individualStats?.goldEarned)} ({individualStats?.challenges?.goldPerMinute.toFixed(1)})</span> */}
            </div>
        )
    }

    function calculateGold(individualStats) {
        let formattedGold = (individualStats?.goldEarned / 1000).toFixed(1);
        if (formattedGold.endsWith('.0')) {
            formattedGold = formattedGold.slice(0, -2);
        }

        return (
            <div className='flex gap-0.5'>
                {/* <div className='fw-bold'>{Intl.NumberFormat().format(individualStats?.goldEarned)} ({individualStats?.challenges?.goldPerMinute.toFixed(0)} GPM)</div> */}
                <div>{formattedGold}k ({individualStats?.challenges?.goldPerMinute.toFixed(0)} GPM)</div>
            </div>
        )
    }

    function calculateGameTimes(matchRecord) {
        let match = matchRecord?.metadata
        let gameEndTime = match?.info?.gameEndTimestamp
        let now = Date.now()
        let unixTimeHoursAgo = (now - gameEndTime) / 1000 / 60 / 60
        let daysAgo = 0
        let minutesAgo = 0
        let hoursAgo = 0

        if (unixTimeHoursAgo < 1) {
            minutesAgo = Math.round(unixTimeHoursAgo * 60)
        } else if (unixTimeHoursAgo > 24) {
            daysAgo = Math.floor(unixTimeHoursAgo / 24)
        } else if (unixTimeHoursAgo >= 1 && unixTimeHoursAgo < 24) {
            hoursAgo = Math.round(unixTimeHoursAgo)
            minutesAgo = Math.round((unixTimeHoursAgo % 24) * 60)
        }

        let duration = new Date(match?.info?.gameDuration * 1000)
        let durationString = duration.toUTCString()
        let formattedDuration = durationString.slice(-11, -4)
        let individualDurations = formattedDuration.split(":")
        let gameHours = parseInt(individualDurations[0])
        let gameMinutes = parseInt(individualDurations[1])
        let gameSeconds = parseInt(individualDurations[2])

        return (
            <div className='text-secondary font-normal'>
                {gameHours ? <span>{gameHours}h {gameMinutes}m {gameSeconds}s</span> : <span>{gameMinutes}m {gameSeconds}s</span>}
            </div>
        )
    }

    function calculateTimeAgo(matchRecord) {
        let match = matchRecord?.metadata
        let gameEndTime = match?.info?.gameEndTimestamp
        let now = Date.now()
        let unixTimeHoursAgo = (now - gameEndTime) / 1000 / 60 / 60
        let daysAgo = 0
        let minutesAgo = 0
        let hoursAgo = 0

        if (unixTimeHoursAgo < 1) {
            minutesAgo = Math.round(unixTimeHoursAgo * 60)
        } else if (unixTimeHoursAgo > 24) {
            daysAgo = Math.floor(unixTimeHoursAgo / 24)
        } else if (unixTimeHoursAgo >= 1 && unixTimeHoursAgo < 24) {
            hoursAgo = Math.round(unixTimeHoursAgo)
            minutesAgo = Math.round((unixTimeHoursAgo % 24) * 60)
        }

        let duration = new Date(match?.info?.gameDuration * 1000)
        let durationString = duration.toUTCString()
        let formattedDuration = durationString.slice(-11, -4)
        let individualDurations = formattedDuration.split(":")
        let gameHours = parseInt(individualDurations[0])
        let gameMinutes = parseInt(individualDurations[1])
        let gameSeconds = parseInt(individualDurations[2])

        return (
            <div className='text-secondary italic text-white'>
                {daysAgo ? <span>{daysAgo} day(s) ago</span> : null}
                {(!daysAgo && hoursAgo) ? <span>{hoursAgo} hour(s) ago</span> : null}
                {(!daysAgo && hoursAgo < 1 && minutesAgo) ? <span>{minutesAgo} minute(s) ago</span> : null}
            </div>
        )
    }


    function calculateKda(individualStats) {
        let kda = (individualStats?.challenges?.perfectGame && individualStats?.challenges?.kda != 0) ? <PiInfinityBold size={14} strokeWidth={2} /> : (individualStats?.challenges?.kda)?.toFixed(1)
        return (
            <div className='flex gap-1'>
                {kda} KDA
            </div>
        )
    }

    function calculateKp(individualStats, matchRecord) {
        let kp = String(individualStats?.challenges?.perfectGame) === "1"
            ? "100"
            : (individualStats?.challenges?.killParticipation)?.toFixed(2).substring(2);

        const kills = individualStats?.kills || 0;
        const deaths = individualStats?.deaths || 0;
        const assists = individualStats?.assists || 0;

        return (
            <div>
                {kills} / {deaths} / {assists}
                {(kills > 0 || deaths > 0 || assists > 0) && matchRecord?.queueId !== 1700 && ` (${kp}%)`}
            </div>
        );
    }


    function renderGameModeRole(matchRecord, individualStats) {
        let match = matchRecord?.metadata
        let gameType = ""
        if (parseInt(match?.info?.queueId) === 420) {
            gameType = "Solo Ranked"
        } else if (parseInt(match?.info?.queueId) === 400) {
            gameType = "Normal"
        } else if (parseInt(match?.info?.queueId) === 490) {
            gameType = "Quick Play"
        } else if (parseInt(match?.info?.queueId) === 450) {
            gameType = "ARAM"
        } else if (parseInt(match?.info?.queueId) === 1300) {
            gameType = "Nexus Blitz"
        } else if (parseInt(match?.info?.queueId) === 1700) {
            gameType = "Arena"
        } else if (parseInt(match?.info?.queueId) === 440) {
            gameType = "Flex"
        } else if (parseInt(match?.info?.queueId) === 700) {
            gameType = "Clash"
        } else if (parseInt(match?.info?.queueId) === 900) {
            gameType = "ARUF"
        } else if (parseInt(match?.info?.queueId) === 1900) {
            gameType = "Pick URF"
        } else if (parseInt(match?.info?.queueId) === 1020) {
            gameType = "One For All"
        } else if (parseInt(match?.info?.queueId) === 470) {
            gameType = "(N) Twisted Treeline"
        } else if (parseInt(match?.info?.queueId) === 720) {
            gameType = "ARAM Clash"
        } else if (parseInt(match?.info?.queueId) === 1400) {
            gameType = "Ult Spellbook"
        } else if (parseInt(match?.info?.queueId) === 480) {
            gameType = "Swift Play"
        } else {
            gameType = "Unknown Game Mode"
        }

        return (
            <>
                <div className="fw-bold fst-italic">{gameType}</div>
                {/* <span className="fw-bold fst-italic">{individualStats?.teamPosition ? <>{individualStats?.teamPosition === "UTILITY" ? "SUPPORT" : individualStats?.teamPosition}</> :null }</span> */}
            </>
        )
    }


    function renderPersonalDamageChart(matchRecord, individualStats) {
        let participants = matchRecord?.metadata?.info?.participants
        let maxDamage = 0
        let maxTanked = 0
        participants?.forEach(player => {
            maxDamage = Math.max(maxDamage, player?.totalDamageDealtToChampions)
            maxTanked = Math.max(maxTanked, player?.totalDamageTaken)
        })
        let personalDamageDealt = individualStats?.totalDamageDealtToChampions
        let personalDamageTaken = individualStats?.totalDamageTaken

        let formattedDamageDealt = (personalDamageDealt / 1000).toFixed(1);
        if (formattedDamageDealt.endsWith('.0')) {
            formattedDamageDealt = formattedDamageDealt.slice(0, -2);
        }

        let formattedDamageTaken = (personalDamageTaken / 1000).toFixed(1);
        if (formattedDamageTaken.endsWith('.0')) {
            formattedDamageTaken = formattedDamageDealt.slice(0, -2);
        }

        let damageDealtPercentage = personalDamageDealt === maxDamage ? 100 : Math.round((personalDamageDealt / maxDamage) * 100)
        let damageTankedPercentage = personalDamageTaken === maxTanked ? 100 : Math.round((personalDamageTaken / maxTanked) * 100)


        return (
            <div className='text-xs flex my-1 mx-3 gap-2 h-6 mb-1'>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div className='h-4'>
                            {/* <RiSwordFill className='h-full' /> */}
                            <img className='h-full w-auto filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-sword-icon.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-900" : "bg-rose-700 border-rose-950"}`}>
                            <div
                                className={`${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"}`}
                                style={{ width: `${damageDealtPercentage}%` }}
                            >
                                <div className='ml-1'>
                                    {formattedDamageDealt}k
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div className='h-4'>
                            <img className='h-full w-auto filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-stat-switcher-shield.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-900" : "bg-rose-700 border-rose-950"}`}>
                            <div
                                className={`${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"}`}
                                style={{ width: `${damageTankedPercentage}%` }}
                            >
                                <div className='ml-1'> {/* Below is needed because Damage Taken in JSON can be undefined if 0 damage is taken, unlike Damage dealt*/}
                                    {formattedDamageTaken ? formattedDamageTaken : 0}k
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    function renderMatchHistory() {
        return matchHistory?.map((matchRecord, index) => {
            // let match = matchRecord?.metadata
            let individualStats = matchRecord?.metadata?.info?.participants?.filter((player) => {
                // return player.riotIdGameName?.toLowerCase() === (params.gameName).toLowerCase() && player.riotIdTagline?.toLowerCase() === params.tagLine.toLowerCase()
                return player?.puuid === summonerData?.puuid && summonerData?.puuid
            })[0]
            // Get a dump of champion name & name Ids, store them as an environment variable & use that as a dict/obj and look up the name from the nameId
            return (
                // <div key={index}>
                //     <h3>{individualStats?.championName} {`[${individualStats?.champLevel}]`}</h3>
                //     {renderGameModeRole(matchRecord, individualStats)}
                //     <>{renderHighestStreak(individualStats)}</>
                //     {calculateKda(individualStats)}
                //     {calculateCsAndGold(individualStats, matchRecord)}
                //     {calculateGameTimes(matchRecord)}
                //     {renderChampionIcon(individualStats)}
                //     {renderSummonerSpells(individualStats)}
                //     {matchRecord?.queueId !== 1700 ?renderRuneIcons(individualStats) : null}
                //     {matchRecord?.queueId === 1700 ? renderAugmentIcons(individualStats) : null}
                //     <div>
                //         {renderItemIcons(individualStats)}
                //     </div>
                //     <h3>{individualStats?.win ? "VICTORY" : "DEFEAT"}</h3>
                //     <>Damage: {individualStats?.totalDamageDealtToChampions}</>
                //     <>Damage Taken: {individualStats?.totalDamageTaken}</>
                //     <>Vision Score: {individualStats?.visionScore}</>
                //     <>Control Wards Placed: {individualStats?.challenges?.controlWardsPlaced}</>
                //     <>{individualStats?.wardsPlaced} / {individualStats?.wardsKilled}</>
                //     {renderParticipantNamesChampions(matchRecord)}
                //     <MatchDetails key={matchRecord?.metadata?.matchId} summonerData={summonerData} matchRecord={matchRecord} renderChampionIcon={renderChampionIcon} navAndSearchParticipant={navAndSearchParticipant} renderParticipantItemIcons={renderParticipantItemIcons} calculateKda={calculateKda}/>
                //     <br></br>
                // </div>
                <div key={index} className={`${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-600" : individualStats?.win ? "bg-cyan-700" : "bg-rose-800"} mb-2 rounded-lg`}>
                    {/* Header */}
                    <div className={`w-full flex gap-2 flex-nowrap items-center text-sm border-b py-1 px-2 rounded-t-lg ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800 border-gray-900" : individualStats?.win ? "bg-cyan-900 border-cyan-950" : "bg-rose-950 border-red-950"}`}>
                        <div className='font-bold'>{(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "REMAKE" : individualStats?.win ? "VICTORY" : "DEFEAT"}</div>
                        <div className='text-xs font-semibold'>{renderGameModeRole(matchRecord, individualStats)}</div>
                        {/* {<div className='text-xs font-semibold bg-emerald-400 rounded-full px-2'>{matchRecord?.metadata?.averageElo}</div>} */}
                        <div className='text-xs font-semibold'>{individualStats?.placement !== 0 && formatArenaPlacement(individualStats?.placement)}</div>
                        {calculateELOBadgeStyling(matchRecord?.metadata?.averageElo)}
                        <div className='text-xs font-semibold'>{calculateGameTimes(matchRecord)}</div>
                    </div>
                    {/* Champion / Rune / Augment */}
                    <div className='flex justify-between items-start px-2 pt-2 pb-1 text-xs'>
                        <div className='flex gap-0.5'>
                            {renderChampionIcon(individualStats, matchRecord)}
                            <div>
                                {renderSummonerSpells(individualStats)}
                                {matchRecord?.queueId !== 1700 ? renderRuneIcons(individualStats) : null}
                                {matchRecord?.queueId === 1700 ? renderAugmentIcons(individualStats) : null}
                            </div>
                        </div>
                        {/* Stats */}
                        <div className='flex flex-col'>
                            <div className='flex gap-0.5 font-semibold'>
                                {/* <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-sword-icon.svg`} /> */}
                                {calculateKp(individualStats, matchRecord)}
                            </div>
                            <div className='flex gap-0.5 items-center'>
                                <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                {calculateKda(individualStats)}
                            </div>
                            <div className='flex gap-0.5 items-center'>
                                <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/minions-slain.svg`} />
                                {calculateCs(individualStats, matchRecord)}
                            </div>
                            <div className='flex gap-0.5 items-center'>
                                <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-coins-icon.svg`} />
                                {calculateGold(individualStats)}
                            </div>
                        </div>
                        <div>
                            {renderItemIcons(individualStats, matchRecord)}
                            {/* <div className='mt-2 text-xs font-semibold bg-emerald-400 rounded-full px-2'> */}
                            {renderHighestStreak(individualStats)}
                            {/* </div> */}
                        </div>
                    </div>
                    {renderPersonalDamageChart(matchRecord, individualStats)}
                    <MatchDetails key={matchRecord?.metadata?.matchId} orderedChampions={orderedChampions} summonerData={summonerData} matchRecord={matchRecord} displayRegion={displayRegion} navAndSearchParticipant={navAndSearchParticipant} calculateKda={calculateKda} calculateTimeAgo={calculateTimeAgo} individualStats={individualStats} formatArenaPlacement={formatArenaPlacement} />
                </div>
            )
        })
    }

    // {/* <div>Vision Score: {individualStats?.visionScore}</div>
    //                 <div>Control Wards Placed: {individualStats?.challenges?.controlWardsPlaced}</div>
    //                 <div>{individualStats?.wardsPlaced} / {individualStats?.wardsKilled}</div> */}
    // {/* {renderParticipantNamesChampions(matchRecord)} */ }


    return (
        <div className='rounded-lg text-white w-full'>
            {matchHistory?.length === 0 ?
                <div className='flex justify-center items-center pb-4'>
                    <div>No games played. Try Updating.</div>
                </div>
                :
                renderMatchHistory()
            }
        </div>
    )
}

export default MatchHistory